#menu2 ul li a {
    color: #fff !important;
    font-weight: bold !important;
}
@media (min-width: 768px) and (max-width: 1023px) {
    #menu2 ul li a {
        color: black !important;
        font-weight: bold !important;
    }
}
